/*.cgi-progress-wrapper {
  position: absolute;
  z-index: 1;
  width: calc(100% - 80px);
  top: 75px;
  right: 40px;
}*/

.pretty-html-wrapper p {
  margin: 2px;
}

.underline {
  text-decoration: underline;
}

.pretty-html-wrapper p .value {
  word-break: break-all;
}

.mat-mdc-header-row .mat-mdc-header-cell {
  text-align: left;
}

.pretty-html-wrapper .key {
  text-transform: capitalize;
}

.cgi-progress-wrapper {
  position: absolute;
  top: 64px;
  width: 100%;
  left: 0;
}

.cgi-flex-content .cgi-flex-item {
  //padding: 10px;
  white-space: nowrap;
  //overflow: hidden;
}

.cgi-flex-content .cgi-flex-item .mdc-button__label {
  text-overflow: ellipsis;
}

.cgi-flex-4-per-row .cgi-flex-item:nth-child(4n) {
  padding: 0px 0px 10px 0px !important
}

.cgi-flex-3-per-row .cgi-flex-item:nth-child(3n) {
  padding: 0px 0px 10px 0px !important
}

.cgi-flex-2-per-row .cgi-flex-item:nth-child(2n) {
  padding: 0px 0px 10px 0px !important
}

.cgi-saved-filter-item .mdc-list-item__primary-text {
  width: 100%;
}

.delete-saved-filter {
  display: flex;
  delete-saved-filter-icon {
    align-self: center;
  }
}

.cgi-saved-filter-item .mdc-list-item__primary-text .delete-saved-filter .mat-icon:hover {
  background: $danger;
  border-radius: 5px;
  color: $white;
}

.mat-mdc-snack-bar-container.snackbar-error {
  --mdc-snackbar-container-color: #ffa726 !important;
}

.mat-mdc-snack-bar-container.snackbar-success {
  --mdc-snackbar-container-color: #24c758 !important;
}

.right-side .dropdown-list {
  position: relative !important;
}

.multiselect-dropdown .selected-item {
  background: #1e88e5 !important;
  border-color: #1e88e5 !important;
}

.cgi-multiselect-drop .multiselect-dropdown .selected-item {
  min-height: 50px;
  padding-top: 12px !important;
}

.cgi-multiselect-drop .multiselect-dropdown .dropdown-btn {
  display: flex !important;
  overflow: scroll !important;
}

.cgi-multiselect .multiselect-dropdown .selected-item {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin-bottom: 5px;
}

.multiselect-dropdown .dropdown-btn .selected-item {
  max-width: 100% !important;
}


.mat-icon {
  vertical-align: inherit;
}

.clickable .mat-mdc-chip-action-label {
  cursor: pointer;
}

.admin-portal-table-container {
  overflow-x: auto;
}

.mat-mdc-row .mat-mdc-cell {
  cursor: initial !important;
}

.error-portal-filters-option .mdc-list-item__primary-text {
  text-overflow: initial;
  word-break: break-all;
  white-space: inherit;
  overflow: auto;
}

.pointer-cursor {
  cursor: pointer;
}

.hidden {
  display: none !important;
}

.justify-content-horizontal-between {
  display: flex;
  justify-content: space-between;
}

body.admin-portal-dark-theme .mat-mdc-button {
  color: $light !important;
}

body.admin-portal-dark-theme .mdc-snackbar .mdc-button__label {
  color: $theme-light-text-muted !important;
}

body.admin-portal-dark-theme .mat-mdc-form-field {
  &.mat-focused {
    .mdc-floating-label {
      color: $light !important;
    }
  }
}

.mat-mdc-option {
  &.lazy-load-input-option .mdc-list-item__primary-text {
    width: 100%;
  }
}


// Hide the checkbox for checking/unchecking all column filters in WorkListComponent and WorkListSentComponent.
app-cgi-multiselect-drag > p-multiselect div.p-multiselect-header > div.p-checkbox.p-component {
  app-work-list &, app-sent-work-list & {
    display: none !important;
  }
}

app-work-list, app-sent-work-list {
  .filter-expansion-panel {
    overflow: visible;
  }
  mat-card {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}

// The styles for the p-multiselect component come from an external library and must be
// overwritten to comply with light/dark theme.
// Some colors for the dark theme are set to hand-picked/"arbitrary" values and do not rely on variables
// from the dark theme as no suitable color to match the p.multiselect component were found in the dark theme.
.p-multiselect {
  body.admin-portal-light-theme & {
    background-color: $theme-light-foreground;
  }
  body.admin-portal-dark-theme & {
    background-color: $theme-dark-foreground;
  }
  div.p-multiselect-items-wrapper {
    body.admin-portal-light-theme & {
      background-color: $theme-light-foreground;
    }
    body.admin-portal-dark-theme & {
      background-color: $theme-dark-foreground;
    }
    p-multiselectitem {
      li.p-multiselect-item {
        &:not(.p-highlight):hover {
          body.admin-portal-dark-theme & {
            background-color: #616161;
          }
        }
        &.p-highlight {
          body.admin-portal-dark-theme & {
            background-color: #263238;
          }
        }
        span {
          body.admin-portal-light-theme & {
            color: $theme-light-text;
          }
          body.admin-portal-dark-theme & {
            color: $theme-dark-text;
          }
        }
      }
    }
    li.p-multiselect-empty-message {
      body.admin-portal-light-theme & {
        color: $theme-light-text-foreground-secondary;
      }
      body.admin-portal-dark-theme & {
        color: $theme-dark-text-foreground-secondary;
      }
    }
  }
  div.p-multiselect-header {
    body.admin-portal-light-theme & {
      background-color: $theme-light-background;
    }
    body.admin-portal-dark-theme & {
      background-color: $theme-dark-background;
    }
    button.p-multiselect-close {
      body.admin-portal-light-theme & {
        background-color: $theme-light-background;
      }
      body.admin-portal-dark-theme & {
        background-color: $theme-dark-background;
      }
      &:hover {
        body.admin-portal-dark-theme & {
          background-color: #616161;
          svg {
            color: #EEEEEE;
          }
        }
      }
      svg {
        body.admin-portal-dark-theme & {
          color: #BDBDBD;
        }
      }
    }
    div.p-multiselect-filter-container {
      input[type="text"] {
        body.admin-portal-light-theme & {
          background-color: $theme-light-foreground;
          color: $theme-light-text;
          caret-color: $theme-light-text;
        }
        body.admin-portal-dark-theme & {
          background-color: $theme-dark-foreground;
          color: $theme-dark-text;
          caret-color: $theme-dark-text;
        }
      }
      svg {
        body.admin-portal-light-theme & {
          color: $theme-light-text;
        }
        body.admin-portal-dark-theme & {
          color: $theme-dark-text;
        }
      }
    }
  }
  div.p-multiselect-label.p-placeholder > div {
    body.admin-portal-light-theme & {
      color: $theme-light-text-foreground-secondary;
    }
    body.admin-portal-dark-theme & {
      color: $theme-dark-text-foreground-secondary;
    }
  }
}

.detail-priors-wrap .mat-mdc-paginator-container {
  justify-content: flex-start !important;
}

.rounded-flag {
  border-radius: 10px;
  width: 20px;
  height: 20px;
}

.mdc-fab-mini-override {
  width: 20px !important;
  height: 20px !important;
  margin: 10px;
  box-shadow: none !important;
}

// CSS for compressing the destination tree.
app-work-list app-cgi-destination-tree {
  mat-tree-node.checklist-tree-node + mat-tree-node.parent-tree-node,
  mat-tree-node.parent-tree-node + mat-tree-node.parent-tree-node,
  mat-tree-node.parent-tree-node + mat-tree-node.checklist-tree-node {
    margin-top: 3px;
  }

  mat-tree-node.checklist-tree-node + mat-tree-node.checklist-tree-node {
    margin-top: -8px;
  }

  mat-tree-node {
    $custom-tree-node-button-height: 25px;
    $custom-tree-node-checkbox-height: 25px;
    min-height: $custom-tree-node-button-height !important;

    button, button span.mat-mdc-button-touch-target, button span.mdc-button__label {
      height: $custom-tree-node-button-height !important;
    }

    button span.mdc-button__label,
    mat-checkbox label.mdc-label {
      text-overflow: ellipsis !important;
      overflow: hidden;
      white-space: nowrap;
      text-align: start;
    }

    div.mat-mdc-tooltip-trigger,
    mat-checkbox,
    div.mdc-form-field,
    mat-checkbox label.mdc-label {
      width: 100%;
    }

    span.mdc-button__label > mat-checkbox,
    mat-checkbox label.mdc-label {
      display: block !important;
    }

    button mat-icon {
      overflow: visible !important;
    }

    mat-checkbox {
      div.mdc-checkbox {
        padding: 7px;
      }
      input.mdc-checkbox__native-control, div.mat-mdc-checkbox-touch-target{
        height: $custom-tree-node-checkbox-height !important;
        width: calc($custom-tree-node-checkbox-height + 6px) !important;
      }
      div.mdc-checkbox__background, input.mdc-checkbox__native-control {
        top: initial !important;
        left: initial !important;
        right: initial !important;
      }
    }
    &.parent-tree-node mat-checkbox {
      height: $custom-tree-node-button-height !important;
      display: inline-flex;
      div.mdc-checkbox {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        padding-left: 0px !important;
      }
      input.mdc-checkbox__native-control, div.mat-mdc-checkbox-touch-target, div.mdc-form-field {
        height: $custom-tree-node-button-height !important;
      }
      div.mdc-checkbox__background, input.mdc-checkbox__native-control {
        top: 0px !important;
        left: 0px !important;
        right: 0px !important;
        bottom: 0px !important;
      }
    }
  }
}

$work-list-compressed-table-size: 24px;
.table-top-action-buttons-container {
  display: flex;
  justify-content: space-between;
}

.compressed-table-action-cells {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.work-list-compressed-table-header {
  th {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
}
.work-list-compressed-table-row {
  height: $work-list-compressed-table-size !important;
  td {
    padding-left: 3px !important;
    padding-right: 3px !important;
    button, button span.mat-mdc-button-touch-target, button span.mdc-button__label {
      height: $work-list-compressed-table-size !important;
      width: $work-list-compressed-table-size !important;
    }
    button {
      display: flex;
      box-sizing: content-box !important;
      justify-content: center;
      padding: 0px !important;
    }
    mat-icon {
      align-self: center;
    }
  }
}

.work-list-compressed-table-header th,
.work-list-compressed-table-row td {
  mat-checkbox {
    div.mdc-checkbox {
      padding: 0px;
      display: flex;
    }
    div.mdc-checkbox, div.mdc-form-field {
      height: $work-list-compressed-table-size !important;
    }
    input.mdc-checkbox__native-control, div.mat-mdc-checkbox-touch-target {
      height: $work-list-compressed-table-size !important;
      width: $work-list-compressed-table-size !important;
    }
    div.mdc-checkbox__background, input.mdc-checkbox__native-control {
      top: initial !important;
      left: initial !important;
      right: initial !important;
      align-self: center;
    }
  }
}

.table-adjustable-columns {
  div.sort-target {
    cursor: pointer;
  }
  div.resize-column {
    resize: horizontal !important;
    display: block !important;
    padding-right: 10px;
    width: fit-content;
    .mat-sort-header-arrow {
      display: none;
    }
  }
  div.mat-sort-header-container, div.resize-cell, div.resize-column, div.sort-target  {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
  }
}

.detail-table {
  thead {
    cursor: initial !important;
  }
}
