/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
// Variables

@use '@angular/material' as mat;
@import '@angular/material/theming';
@import 'typography.scss';
@import 'palettes.scss';
/*Material Theme Colors*/

$admin-portal-primary-light: mat.define-palette(mat.$blue-palette, 600);
$admin-portal-accent-light: mat.define-palette($custom-purple-palette, 500);
$admin-portal-warn-light: mat.define-palette(mat.$orange-palette, 400);

$admin-portal-theme-light: mat.define-light-theme(
    (
      color: (
        primary: $admin-portal-primary-light,
        accent: $admin-portal-accent-light,
        warn: $admin-portal-warn-light,
      ),
      typography: $custom-typography,
      density: 0,
    ));

$admin-portal-primary-dark: mat.define-palette(mat.$blue-palette, 600);
$admin-portal-accent-dark: mat.define-palette(mat.$blue-grey-palette, 500);
$admin-portal-warn-dark: mat.define-palette(mat.$orange-palette, 400);

$admin-portal-theme-dark: mat.define-dark-theme(
    (
      color: (
        primary: $admin-portal-primary-dark,
        accent: $admin-portal-accent-dark,
        warn: $admin-portal-warn-dark
      ),
      typography: $custom-typography,
      density: 0,
    ));


// Technically not variables but since it is directly related to the themes
// defined above it is suitable to put this here.

// As per the Material documentation, default to using the light theme and then use the
// dark theme colors when appropriate to avoid generating the same typography twice.
@include mat.all-component-themes($admin-portal-theme-light);
@include mat.all-legacy-component-themes($admin-portal-theme-light);

body.admin-portal-dark-theme {
  @include mat.all-component-colors($admin-portal-theme-dark);
  @include mat.all-legacy-component-colors($admin-portal-theme-dark);
}


/*Theme Colors*/

// Light theme
$theme-light-primary: mat.get-color-from-palette($admin-portal-primary-light, 'default');
$theme-light-warning: mat.get-color-from-palette($admin-portal-warn-light, 'default');
$theme-light-light-danger: #f9e7eb;
$theme-light-background: map-get(map-get($admin-portal-theme-light, 'background'), 'background');
$theme-light-foreground: map-get(map-get($admin-portal-theme-light, 'background'), 'card');
$theme-light-info: #beeaff;
$theme-light-text: map-get(map-get($admin-portal-theme-light, 'foreground'), 'text');
$theme-light-text-contrast-primary: mat.get-color-from-palette($admin-portal-primary-light, 'default-contrast');
$theme-light-text-foreground-secondary: map-get(map-get($admin-portal-theme-light, 'foreground'), 'secondary-text');
$theme-light-text-muted: rgb(0, 0, 0, 0.45);

// Dark theme
$theme-dark-primary: mat.get-color-from-palette($admin-portal-primary-dark, 'default');
$theme-dark-warning: mat.get-color-from-palette($admin-portal-warn-dark, 'default');
$theme-dark-light-danger: #d13d5e;
$theme-dark-background: map-get(map-get($admin-portal-theme-dark, 'background'), 'background');
$theme-dark-foreground: map-get(map-get($admin-portal-theme-dark, 'background'), 'card');
$theme-dark-info: #455A64;
$theme-dark-text: map-get(map-get($admin-portal-theme-dark, 'foreground'), 'text');
$theme-dark-text-contrast-primary: mat.get-color-from-palette($admin-portal-primary-dark, 'default-contrast');
$theme-dark-text-foreground-secondary: map-get(map-get($admin-portal-theme-dark, 'foreground'), 'secondary-text');
$theme-dark-text-muted: rgb(255,  255, 255, 0.45);

$theme-light-and-dark-info-dark: #7460ee;
$headingtext: #455a64;
$bodytext: #67757c;

$font-16: 16px;

/*bootstrap Color*/
$danger: #fc4b6c;
$success: #24c758;
$inverse: #2f3d4a;
$light: #f2f4f8;
$extra-light: #ebf3f5;

/*Light colors*/
$light-success: #e8fdf8;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #e3f3fd;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;

/*Normal Color*/
$white: #ffffff;
$red: #fb3a3a;
$yellow: #a0aec4;
$purple: #7460ee;
$blue: #295bac;
$megna: #00897b;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$table-border: #f3f1f1;
$card-brd: #d7dfe3;
$dark-text: #848a96;
$radius: 4px;
$form-brd: #d9d9d9;

/*$page-wrapper-boxed-width: 1300px*/
$page-wrapper-boxed-width: 100%;

$mat-card-margin: 15px;
